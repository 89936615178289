<template>
  <div :class="['flex', 'rounded-full', setOutline, setBg, setSize, setPadding]">
    <img class="mx-auto my-auto cursor-pointer" 
      :src="src" 
      :alt="alt">
  </div>
</template>

<script>

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    outline: {
      type: Boolean,
      required: false,
      default: true
    },
    outlineColor: {
      type: String,
      required: false,
      default: 'redDamask'
    },
    bg: {
      type: String,
      required: false,
      default: ''
    },
    size: {
      type: String,
      required: false,
      default: 'w-10 h-10'
    },
    padding: {
      type: String,
      required: false,
      default: 'p-2.5'
    }
  },
   data () {
    return {
      alt: this.name,
      outlineCircle: this.outline,
      bgColor: this.bg,
      iconSize: this.size
    }
  },
  computed: {
    setOutline() {
      return (this.outlineCircle == true) ? this.outlineColor ? `border border-${this.outlineColor}` : 'border border-redDamask' : 'border-none'
    },
    setBg() {
      return this.bgColor ? this.bgColor : ''
    },
    src() {
      return require("../assets/icons/" + this.name + ".svg")
    },
    setSize() {
      return this.iconSize ? this.iconSize : 'w-10 h-10'
    },
    setPadding() {
      return this.padding ? this.padding : 'p-2.5';
    }
  }
}
</script>